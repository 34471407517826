import React from "react"
import Layout from "../components/Layout"
import indexStyle from "../scss/index.module.scss"
import { Link } from "gatsby"
import Section from "../components/Section"
import Hero from "../components/Hero"
import Gallery from "../components/Gallery"

const Index = () => {
  return (
    <Layout>
      <Hero />
      <section className={indexStyle.content}>
        <div className={indexStyle.welcome}>
          <div className={indexStyle.innerWelcome}>
            <h3>Welcome</h3>
            <p>
              At Beech &amp; Greene Painting and Decorating we pride ourselves
              on achieving the highest possible standards and going above and
              beyond the clients needs and expectations.
            </p>

            <p>
              Our team of highly trained, professional female painters and
              decorators have all the skills and knowledge to fulfill your
              desired goal. Would you like to freshen up your home, are you
              going to put your property on the market, or are you moving into a
              new house or flat? Are you a commercial business that requires our
              services, whether you want to paint the exterior or the interior,
              we will ensure you get a beautiful result every time. Beech &amp;
              Greene work in both large and small residential and commercial
              properties, all around the Bristol and South Gloucestershire
              areas.
            </p>
            <p>
              We are a reliable, friendly and experienced team, when you hire
              us, you can expect excellent and superior workmanship alongside
              focused and friendly customer service. From planning to
              completion, you can rely on Beech &amp; Greene.{" "}
            </p>
            <div className={indexStyle.contact}>
              <Link to="/contact">Get In Touch</Link>
            </div>
          </div>
        </div>
      </section>
      <Section />
      <section className={indexStyle.GallerySection}>
        <Gallery />
      </section>
    </Layout>
  )
}

export default Index
