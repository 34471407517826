import React from "react"
import { graphql, StaticQuery } from "gatsby"
import heroStyles from "./scss/hero.module.scss"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const Hero = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "paintbrush.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logo: file(relativePath: { eq: "logoWhite.png" }) {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.image.childImageSharp.fluid

      return (
        <BackgroundImage className={className} fluid={image}>
          <div className={heroStyles.hero}>
            <h2>Beech &amp; Greene</h2>
            <h3>Painting and Decorating</h3>
            <h4>Specialists In Eco Friendly &amp; Traditional Paints</h4>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const BackgroundImageStyle = styled(Hero)`
  background-position: center;
`

export default BackgroundImageStyle
