import React from "react"
import galleryStyles from "./scss/gallery.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Gallery = () => {
  const image = useStaticQuery(graphql`
    query {
      before1: file(relativePath: { eq: "1a.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before2: file(relativePath: { eq: "1b.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before3: file(relativePath: { eq: "2a.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before4: file(relativePath: { eq: "2b.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before5: file(relativePath: { eq: "3a.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before6: file(relativePath: { eq: "3b.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before7: file(relativePath: { eq: "outside1a.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before8: file(relativePath: { eq: "outside1b.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before9: file(relativePath: { eq: "before0a.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before10: file(relativePath: { eq: "before1a.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)

  return (
    <div className={galleryStyles.gallery}>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={image.before1.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={image.before2.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={image.before3.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={image.before4.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={image.before5.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={image.before6.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={image.before7.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={image.before8.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={image.before9.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={image.before10.childImageSharp.sizes}
        />
      </div>
    </div>
  )
}

export default Gallery
