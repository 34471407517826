import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import heroStyles from "./scss/hero.module.scss"

const Hero = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        imag: file(relativePath: { eq: "team.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.imag.childImageSharp.fluid

      return (
        <BackgroundImage className={className} fluid={image}>
          <div className={heroStyles.section}>
            <h3>Testimonials</h3>
            <div className={heroStyles.testimonialWrap}>
              <div className={heroStyles.testimonial}>
                <q>
                  Amy and Rowena did a great, professional, job for me,
                  redecorating living room and kitchen. They were punctual, hard
                  working and very flexible, nothing was too much trouble, and I
                  love what they have done. Thanks to both of you.
                </q>

                <p>Liz</p>
              </div>
              <div className={heroStyles.testimonial}>
                <q>
                  These two are the best tradespeople we've worked with. Their
                  communication and flexibility are genuinely exceptional, and
                  they did a lovely job of our three bedrooms, stairs, hallway
                  and landing. I tried to pay them more and they refused! Can't
                  recommend them highly enough.
                </q>
                <p>Scott</p>
              </div>
              <div className={heroStyles.testimonial}>
                <q>
                  Amy and Rowena did a fantastic job helping us decorate The
                  Barn @ Dorlands, getting it ready for our first wedding! They
                  both worked so hard and nothing was too much trouble. We would
                  certainly recommend Beech & Greene, we will be booking them
                  back soon to do the bedrooms next!
                </q>

                <p>Helen</p>
              </div>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const BackgroundImageStyle = styled(Hero)`
  background-position: bottom;
`

export default BackgroundImageStyle
